import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  open(event) {
    event.preventDefault()
    let modal = event.target.dataset.value
    //console.log(modal)
    document.getElementById(modal).classList.add('block')
    // this.modalTarget.classList.add('block')
    // console.log("ewjfieojerg")
  }

  close() {
    let modal = event.target.dataset.value
    document.getElementById(modal).classList.remove('block')
  }
}
