import { Controller } from "@hotwired/stimulus"

const MAX_LENGTH = 200

export default class extends Controller {
  static targets = [ "text" ]

  restrict(event) {
    let text = this.textTarget.value

    if (text.length > MAX_LENGTH) {
      this.textTarget.value = text.substring(0, MAX_LENGTH)

      alert(`You can't type more than ${MAX_LENGTH} characters`)
    }
  }
}
