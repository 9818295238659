import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "wrap" ]

  removeNotification(event) {
    var elem = this.wrapTarget
    elem.parentNode.removeChild(elem);
  }
}
