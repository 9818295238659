import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "score", "response", "comments", "option", "container", "hidden" ]

  setScore() {
    let dropdown = this.optionTarget
    let response = dropdown.options[dropdown.selectedIndex].text
    if (response == 'Select option') {
      this.scoreTarget.value = ''
    } else {
      this.scoreTarget.value = dropdown.options[dropdown.selectedIndex].dataset.type;
      console.log(typeof dropdown.options[dropdown.selectedIndex].dataset.type)
      if (dropdown.options[dropdown.selectedIndex].dataset.comment != undefined) {
        this.showField()
        let overwriteComment = false
        if (this.commentsTarget.value == '') {
          overwriteComment = true
        } else {
          for (var i = 0; i < dropdown.options.length; i++) {
            if (dropdown.options[i].dataset.comment == this.commentsTarget.value) {
              overwriteComment = true
              break;
            }
          }
        }
        if (overwriteComment) {
          this.commentsTarget.value = dropdown.options[dropdown.selectedIndex].dataset.comment;
        }
      }
    }
    let overwriteResponse = false
    if (this.responseTarget.value == '') {
      overwriteResponse = true
    } else {
      for (var i = 0; i < dropdown.options.length; i++) {
        if (dropdown.options[i].text == this.responseTarget.value) {
          overwriteResponse = true
          break;
        }
      }
    }

    if (overwriteResponse) {
      this.responseTarget.value = response
    }
    // this.containerTarget.classList.add(`score-${score}`)
  }

  showField() {
    this.hiddenFieldsController.showField()
  }

  get hiddenFieldsController() {
    return this.application.getControllerForElementAndIdentifier(this.hiddenTarget, "hidden-fields")
  }

}
