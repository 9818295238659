import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  open(event) {
    let item = event.target.dataset.accordion
    //console.log(item)
    for (var i = 0; i < this.itemTargets.length; i ++) {
      this.itemTargets[i].classList.remove('accord-item--open')
    }
    document.getElementById(`accordion-${item}`).classList.add('accord-item--open')
  }
}
