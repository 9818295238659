// import Turbolinks from "turbolinks";
import Rails from '@rails/ujs';
import "@stimulus/polyfills"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import "@hotwired/turbo-rails"

require("flatpickr/dist/themes/airbnb.css");
Rails.start();

import "../css/application.css";
import '../images';

// Stimulus
const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
