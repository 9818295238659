import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  downloadPDF(event) {
    event.preventDefault();
    fetch(event.currentTarget.href, {
      headers: { 'Turbo-Frame': 'pdf_frame' }
    }).then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const frame = document.getElementById('pdf_frame');
        frame.src = url;
      });
  }
}
