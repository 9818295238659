import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "documentType", "link", "document" ]

  typeToggle() {
    //this.modalTarget.classList.add('block')
    let typeSelected = this.documentTypeTarget.value
    //console.log(typeSelected)
    if (typeSelected == 'document') {
      //console.log("Wkfjoirjgorjgoire")
      this.showDocument()
    } else {
      this.showLink()
    }
  }

  showLink() {
    this.linkTarget.classList.remove('hidden')
    this.linkTarget.classList.add('block')
    this.documentTarget.classList.remove('block')
    this.documentTarget.classList.add('hidden')
  }

  showDocument() {
    this.documentTarget.classList.remove('hidden')
    this.documentTarget.classList.add('block')
    this.linkTarget.classList.remove('block')
    this.linkTarget.classList.add('hidden')
  }
}
