import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tab', 'panel']

  initialize() {
    this.showTab()
  }

  change(event) {
    event.preventDefault()
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      let activeClass = tab.dataset.active
      if (index === this.index) {
        tab.classList.add(activeClass)
        panel.classList.remove('hidden')
      } else {
        tab.classList.remove(activeClass)
        panel.classList.add('hidden')
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || -1)
  }

  set index(value) {
    this.data.set('index', value)
    this.showTab()
  }
}
